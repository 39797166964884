<template>
  <el-container style="height: 100%; text-align: left;">
    <el-header>
        <Header></Header>
    </el-header>
    <el-container style="height: 100%; text-align: left">
      <el-aside :width="isCollapse?'64px':'200px'" style="height: 100%; overflow-x: hidden">
        <el-scrollbar style="height: 100%; background-color: #ffffff; overflow-x: hidden">
          <el-menu class="el-menu-vertical-demo" router :collapse="isCollapse"
           unique-opened :active-text-color="'#0ba882'" :default-active="$route.path">
            <Menu :menuTree="menuTree"></Menu>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <el-container style="height: 100%; text-align: left">
        <el-header height="48px">
          <div style="display: flex;align-items: center;height: 100%;padding-left: 10px;">
            <div class="menu-handle">
              <span @click="handleMenu"><i :class="isCollapse?'el-icon-s-unfold':'el-icon-s-fold'"></i></span>
              <!-- <span @click="$router.push('/bigconfig/activity/index')"><i class="el-icon-s-home"></i></span> -->
            </div>
            <div class="act-info-top">
              <span style="font-size:18px;">{{actObj?actObj.actName:''}}</span>
            </div>
          </div>
        </el-header>
        <el-main style="padding:0px;width:100%;height:100%;background-color:#f8f8f8;">
          <el-scrollbar style="height: 100%; width: 100%;">
            <div style="padding:10px;">
              <router-view style="padding:10px;background-color:#ffffff;"></router-view>
            </div>
            <div class="copyright">
              <span style="font-size:14px;color:#ababab;">Copyright © 2021-2022 版权所有 侵权必究</span>
            </div>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import Menu from './menu/index.vue'
import Header from './header/index.vue'
export default {
  name: "SetttingHome",
  components:{Menu,Header},
  data() {
    return {
      isCollapse:false,
      menuTree:[],
      actObj:null
    };
  },
  mounted() {
    this.actObj = this.$store.state.actObj;
    this.menuTree = this.$store.state.menu;
  },
  created(){
  },
  methods:{
    handleMenu(){
      this.isCollapse=!this.isCollapse;
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-container {
  overflow: hidden;
}
/deep/ .el-header {
  padding: 0;
}
/deep/ .el-aside {
  overflow-x: hidden;
  background-color: #ffffff;
}
/deep/ .el-menu {
  border-right: none;
}
//解决动态生成菜单之后，收缩之后submenu的文字不消失问题
/deep/.el-menu--collapse .el-submenu__title span{
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline-block;
  }
/deep/.el-menu--collapse .el-submenu__icon-arrow{
  display: none;
}
// end
/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
  .el-scrollbar__view {
    height: 100%;
  }
}
.copyright {
  margin: 10px 0px;
  text-align: center;
}
.menu-handle{
  span{
    font-size: 30px;
    color: #0ba882;
    margin-right: 15px;
    &:hover{
      cursor: pointer;
      color: #2aebbc;
    }
  }
  .act-info-top{
    span{
      font-size: 18px;
    }
  }
}
</style>